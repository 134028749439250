import React, { useEffect } from 'react'

import { navigate } from 'gatsby'

import { FullPageLoader } from 'components/Loader'

export default () => {
  useEffect(() => {
    navigate('/app/settings/prime', { replace: true })
  }, [])

  return <FullPageLoader />
}
